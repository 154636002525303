<template>
  <div class="settings-billing">
    <div v-if="isPropertyLevel">
      <HbPropertySelector
        id="facility"
        :items="facilityList"
        item-text="name"
        item-value="id"
        v-validate="'required'"
        v-model="property_id"
        data-vv-name="facility"
        data-vv-as="Property"
        :error="errors.has('facility')"
      />
    </div>

    <div class="mr-6" :class="{'pt-1' : !isPropertyLevel}">
      <v-expansion-panels
        v-if="property_id || !isPropertyLevel"
        v-model="panel"
        multiple
        :disabled="(isPropertyLevel && !property_id) ? true : false"
      >
        <hb-expansion-panel :top-margin="false">
          <template v-slot:title>
            Transactional
          </template>
          <template v-slot:content>
            <transactional
              :level="level"
              :facilityID.sync="property_id"
            >
            </transactional>
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel key="reversals">
          <template v-slot:title>
            Reversals
          </template>
          <template v-slot:content>
            <reversals
              :level="level"
              :facilityID.sync="property_id"
            >
            </reversals>
          </template>
        </hb-expansion-panel>

        <hb-expansion-panel v-if="!isPropertyLevel">
          <template v-slot:title>
            Merchandise Management
          </template>
          <template v-slot:content>
            <merchandise-settings :is-merchandise-enable="isMerchandiseEnable"/>
          </template>
        </hb-expansion-panel>
        
        <hb-expansion-panel :key="property_id">
          <template v-slot:title>
            Invoice Notification
          </template>
          <template v-slot:content>
            <pdf-statement-attachment :facilityID.sync="property_id"/>
          </template>
        </hb-expansion-panel>
      </v-expansion-panels>

      <hb-empty-state
        v-else
        message="Please select a Property to continue."
      >
      </hb-empty-state>
    </div>
  </div>
  <!-- <div class="section-content">
    <div class="primary-section-content">
      <div>
        <status @resetStatus="errorClear($options.name)" v-if="(errorHas($options.name) || errors.has('offset') || errors.has('taxrate')) && errorMsg !== ''" :message="errorMsg" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
        
        <v-card outlined style="border-radius: 0px;">
          <v-card-text class="pl-3 py-0 pr-0 hide-overflow">
            <v-row class="mr-0">
              <v-col class="pa-0" cols="3">
                <div class="billing-label">
                  <div class="pa-4 pl-6">
                    <span class="font-weight-medium hb-default-font-size">Account Auto-Charge Offset</span>
                  </div>
                </div>
              </v-col>
              <v-col class= "py-0" cols="9">
                <div class="pt-4 pb-7 px-3">
                  <p class="hb-text-light hb-default-font-size">Enter number of days before invoice due date you want to process payments.</p>
                  <div style="display:inline-flex">
                    <v-text-field 
                      v-model="settings.invoiceChargeOffset"
                      v-validate="'required|integer|min:0|max:45'"
                      class="w-input"
                      style="width: 90px;"
                      id="offset"
                      name="offset"
                      :error="errors.has('offset')"
                    >
                     :error-messages="errors.first('offset')" -->
  <!-- </v-text-field>
                    <p class="mb-0 pt-2 ml-2">Days</p>
                  </div>

                </div>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          
            <v-row class="mr-0">
              <v-col class="pa-0" cols="3">
                <div class="billing-label">
                  <div class="pa-4 pl-6">
                    <span class="font-weight-medium hb-default-font-size">Enter tax rate</span>
                  </div>
                </div>
              </v-col>
              <v-col class= "py-0" cols="9">
                <div class="pt-4 pb-7 px-3">
                  <p class="hb-text-light mb-0 hb-default-font-size pb-4">Enter the sales tax rate for merchandise that requires tax. If you are a QuickBooks user, this rate can be overridden by the rate set up in that application.</p>
                  <div style="display:inline-flex">
                    <v-text-field 
                      v-model="settings.taxRate"
                      v-validate="'required|decimal:2|max:45'"
                      class="w-input"
                      style="width: 95px;"
                      placeholder="7.25"
                      id="taxrate"
                      name="taxrate"
                      :error="errors.has('taxrate')"
                    >
                    </v-text-field>
                    <p class="mb-0 pt-2 ml-2">%</p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>         
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="py-3">
            <v-spacer></v-spacer>
            <a 
              class="mr-2"
              @click="cancel"
            >
              Cancel
            </a> 
            <hb-btn
              class="mr-2"
              color="primary"
              :disabled="isLoading($options.name)"
              @click="save"
              :loading="isLoading($options.name)"
            >
              Send
            </hb-btn>
          </v-card-actions>
        </v-card>
      </div>
  </div>
  </div> -->
</template>

<script type="text/babel">
import Transactional from "./Transactional/Index.vue";
import Reversals from "./Reversals/Index.vue";
import PdfStatementAttachment from "./PdfStatementAttachment/Index.vue";
import Status from "../includes/Messages.vue";
import api from "../../assets/api.js";
import settingsTabMixin from '../../mixins/settingsTabMixin.js';
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from "../../mixins/notificationMixin.js";

export default {
  name: "BillingSettings",
  mixins:[settingsTabMixin, notificationMixin],
  props: ['level'],
  data() {
    return {
      panel: [],
      category: "billing",
      settings: {
        invoiceChargeType: "$",
        defaultDays: "",
        lockoutDays: "",
        auctionDays: "",
        invoiceSendDay: "",
        taxRate: "",
        invoiceChargeOffset: "",
      },
      lateTypes: ["$", "%"],
      errorMsg: "",
      property_id: null
    };
  },
  components: {
    Transactional,
    Reversals,
    Status,
    PdfStatementAttachment,
    'merchandise-settings' : () => import('./MerchandiseManagement/MerchandiseSettings.vue')
  },
  created() {
    this.fetchData();
  },
  computed:  {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      defaultProperty: "propertiesStore/defaultProperty"
    }),
    isPropertyLevel() {
      return this.level === 'Property' ? true : false;
    }
  },
  watch: {
    property_id() {
      if (this.property_id) {
        this.setDefaultProperty(this.facilityList.find(e => e.id == this.property_id));
      }
    },
    level() {
      if(this.level == 'Property'){
        this.property_id = this.defaultProperty ? this.defaultProperty.id : null
      }
    }
  },
  methods: {
    ...mapActions({
      setDefaultProperty: 'propertiesStore/setDefaultProperty',
    }),
    initializeData(){
      this.panel = [];
      this.settings = [];
    },
    fetchData() {
      api.get(this, api.SETTINGS_BILLING).then((r) => {
        this.settings = r.settings;
      });
    },
    save() {
      var save = {
        category: this.category,
        data: this.settings,
      };
      this.validate(this).then((status) => {
        if (!status) {
          this.errorMsg = "";
          if (this.errorHas(this.$options.name)) {
            this.errorMsg = this.errorGet(this.$options.name) + "<br>";
          }

          if (this.errors.has("offset")) {
            this.errorMsg += this.errors.first("offset") + "<br>";
          }

          if (this.errors.has("taxrate")) {
            this.errorMsg += this.errors.first("taxrate");
          }
          return;
        }
        api.post(this, api.SETTINGS, save).then((r) => {
          this.showMessageNotification({ id: this.$options.name, type: "success", description: 'Billing settings Updated' });
        });
      });
    },
    cancel() {
      this.$router.push({ path: "/settings" });
    },
  }
};
</script>

<style lang="scss" scoped>
.settings-billing .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px;
}
.billing-label {
  background-color: #f9fafb;
  height: 100%;
  min-height: 108px;
  width: 100%;
  color: #101318;
  display: flex;
}
</style>
